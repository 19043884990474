import React from "react"
import { Container, Heading, Text, Box } from "@chakra-ui/react"

import { useContent } from "@app/hooks/useContent"
import type { Props } from "@app/templates/generic"
import type { PageProps } from "@root/types/global"

const Generic: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)
  return (
    <Container maxW="container.md" minH="calc(100vh - 531px)" py={{ base: "8", lg: "18" }} px={{ base: "4", lg: "20" }}>
      <Heading as="h1" size="h1" textAlign="center" color="typography.headlines900">
        {page?.title}
      </Heading>

      <Text
        as={Box}
        mt={{ base: "4", lg: "8" }}
        sx={{
          "*": { mb: { base: "4", lg: "5" } },
          "*:last-child": { mb: "0" },
          a: { color: "brand.primary", cursor: "pointer" },
          img: { mx: "auto", borderRadius: "xl" },
          ul: { ml: "4" },
          ol: { ml: "4" },
        }}
        maxW="160"
        mx="auto"
      >
        {content}
      </Text>
    </Container>
  )
}

export default Generic

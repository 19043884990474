import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Generic"

export type Props = PageProps<GatsbyTypes.TemplateGenericQuery, GatsbyTypes.TemplateGenericQueryVariables>

export const query = graphql`
  query TemplateGeneric($id: String!) {
    page: sanityPageGeneric(_id: { eq: $id }) {
      title
      handle {
        current
      }
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
